@import "../base/vars";
@import "../vendors/media";

.button, .btn {
	display: inline-block;
	border: 1px solid #dedede;
	padding: 12px 25px;
	color: $blue;
	font-weight: 500;
	text-transform: uppercase;
	transition: all .3s ease;
	letter-spacing: 1.5px;
	background: transparent;
	font-family: $firasans;
	border-radius: 0;
	font-size: 1.3rem;
	line-height: 22px;

	@media #{$tablet} {
		padding: 5px 25px
	}

	@media #{$mobile} {
		font-size: 1.4rem;
		padding: 5px 20px;
	}

	&:hover, &:focus {
		color: $blue;
		border-color: $blue;
		text-decoration: none;
	}

	&-wrap {
		padding-top: 4%;
	}

	&-small {
		font-size: 1.3rem;
		padding: 7px 22px;
	}

	&-dropdown {
		font-size: 2rem;
		border-left: 1px solid $white !important;
		padding: 0 7px 1px;

		i {
			text-align: center;
		}
	}

	&-discount {
		border: 2px solid $white;

		&:hover, &:focus {
			border-color: $white;
			color: $blue;
			background-color: $white;
		}

		&-wrap {
			text-align: right;

			@media #{$laptop} {
				text-align: center;
			}
		}
	}

	&.disabled {
		color: #dedede;

		&:hover, &:focus {
			border-color: #dedede;
		}
	}

	&.btn-green {
		@extend .button--blue;
	}

	&-big {
		font-size: 1.4rem;
		padding: 18px 40px;
	}
}

.button--blue, .btn-blue {
	background-color: $blue;
	border-color: $blue;
	color: $white;
	transition: background-color .1s ease;

	&:hover, &:focus {
		color: $white;
	}

	&:hover {
		background-color: lighten($blue, 5%);
	}

	&:active {
		background-color: darken($blue, 5%);
	}
}

.btn-group-wrap {
	width: 157px;
}

.button-single {
	width: 100%;
}

table.order a span.buy-button {
	float: right;
	margin-left: 20px;
	display: block;

	@extend .btn;
	@extend .button--blue;
}

.btn-group {
	padding-top: 30px;

	.btn {
		margin-right: 20px;
	}

	&-link {
		padding-top: 20px;
	}
}

.btn-link {
	text-transform: uppercase;
	font-weight: 500;
	font-family: $firasans;
	font-size: 1.4rem;
	margin-right: 30px;
	letter-spacing: 1.5px;

	&--black {
		color: $title-color;
	}
}

.top {
	position: fixed;
	bottom: 100px;
	right: -100px;
	background-color: #222227;
	border: 1px solid #39393d;
	z-index: 12;
	color: $white;
	font-size: 22px;
	width: 38px;
	height: 38px;
	text-align: center;
	line-height: 32px;
	cursor: pointer;
	transition: all .3s ease;
	opacity: .5;

	&:hover {
		opacity: 1;
	}

	&.active {
		right: 60px;
	}
}

#productButtons {
	white-space: nowrap;
}

.btn-search {
	border: 4px solid $blue;
}

.button-link {
	font-size: 1.4rem;
	color: $blue;
	text-transform: uppercase;
	font-family: $firasans;
	font-weight: 500;
	margin-top: 40px;
	display: inline-block;
	letter-spacing: 1.5px;

	&:hover {
		text-decoration: none;
		border-bottom: 2px solid $blue;
	}
}

.btn-big {
	font-size: 1.6rem;
	padding: 18px 25px;
}