@import "../base/vars";
@import "../vendors/media";

.doc-accordion {
	padding-top: 40px;
}

.box-row {
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	padding-left: 13px;

	@media #{$tablet} {
		padding-left: 0;
	}
}

.box-col {
	text-align: center;
	width: 25%;
	padding: 15px 3% 25px;
	transition: box-shadow .3s ease;
	border: 1px solid transparent;

	@media #{$laptop} {
		width: 50%;
	}

	@media #{$mobile} {
		width: 100%;
	}

	&:hover {
		background-color: $white;
		box-shadow: 0px 21px 49px rgba(47,47,47,0.16);
		border: 1px solid rgba(245,246,247,0.9);
	}

	.logo {
		img {
			width: 84px;
			height: 84px;
		}
	}

	.name {
		line-height: 24px;
		text-align: center;

		&-link {
			color: $title-color;
			font-size: 1.8rem;
			font-family: $firasans;
		}
	}
}

.collapse {
	&-link {
		font-size: 2.8rem;
		font-weight: 300;
		color: $blue !important;
		display: flex;

		&:hover, &:focus {
			border: none !important;
			text-decoration: none;
		}

		&.collapsed {
			color: $title-color !important;
		}
	}

	&-icon {
		border: 1px solid #e6e6e6;
		border-radius: 50%;
		padding: 5px;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 18px;
		color: $title-color;
		font-size: 1.4rem;
		font-weight: 100;
		margin-right: 10px;
	}

	&-title {
		font-size: 4.8rem;
		color: $title-color;
		font-weight: 300;
		line-height: 0.8;
		padding-bottom: 40px;
	}
}

/*Tabs Documentotion*/
.tabs-doc {
	padding: 20px 0 0 0;

	li {
		height: 7rem;
		padding: 0;

		&.active {
			.tabs-doc-item {
				color: $white;
				position: relative;

				&::before {
					position: absolute;
					content: "";
					border: 14px solid transparent;
					border-right: 14px solid $white;
					z-index: 9999;
					right: 0;
					transform: translate(0, -50%);
					top: 50%;
				}

				i {
					color: $white;
				}
			}

			.purple {
				background-color: #8719db;

				&:hover {
					background-color: darken(#8719db, 5%);
				}
			}

			.red {
				background-color: #e53d47;

				&:hover {
					background-color: darken(#e53d47, 5%);
				}
			}

			.blue {
				background-color: #0091ff;

				&:hover {
					background-color: darken(#0091ff, 5%);
				}
			}

			.navy {
				background-color: #073db7;

				&:hover {
					background-color: darken(#073db7, 5%);
				}
			}

			.yellow {
				background-color: #ffc300;

				&:hover {
					background-color: darken(#ffc300, 5%);
				}
			}

			.yellow-green {
				background-color: #8ddf19;

				&:hover {
					background-color: darken(#8ddf19, 5%);
				}
			}

			.irish-green {
				background-color: #3edf19;

				&:hover {
					background-color: darken(#3edf19, 5%);
				}
			}

			.orange {
				background-color: #ff8700;

				&:hover {
					background-color: darken(#ff8700, 5%);
				}
			}

			.persian-green {
				background-color: #00cf89;

				&:hover {
					background-color: darken(#00cf89, 5%);
				}
			}

			.green {
				background-color: #00cfb4;

				&:hover {
					background-color: darken(#00cfb4, 5%);
				}
			}
		}

		&:last-child {
			.tabs-doc-item {
				border: none;
			}
		}
	}

	&-item {
		width: 100%;
		font-size: 2.2rem;
		line-height: 24px;
		font-family: $firasans;
		font-weight: 400;
		color: $title-color;
		background-color: #fafbfc;
		display: flex;
		align-items: center;
		padding: 0 30px;
		height: 7rem;
		transition: background-color .3s ease;
		border-bottom: 1px solid #f0f1f2;

		&:hover, &:focus {
			color: $title-color;
			background-color: darken(#fafbfc, 5%);
			text-decoration: none;
		}

		@media #{$laptop} {
			font-size: 1.8rem;
			line-height: 20px;
		}

		i {
			color: #d9d9d9;
			font-size: 1.8rem;
			margin-right: 30px;
		}
	}
}

.doc-desc {
	padding-left: 13px;

	@media #{$tablet} {
		padding-left: 0;
	}
}

.searchbox {
	@media #{$tablet} {
		top: 7px;
	}
}

.menu-container-item {
	.row {
		margin-right: 0;
	}
}

#nav-mobile .toggle-btn {
	@media #{$tablet} {
		width: 60px;
	}
}