@import "../base/vars";
@import "../vendors/media";

.s-products_all {
	display: none;
}

.all-products {
	padding-top: 70px;

	&__desc {
		padding: 20px 0;
	}

	.products-box__content-title {
		font-size: 2.2rem;
	}

	.products-box {
		padding: 30px 50px;
	}

	.s-products {
		padding-bottom: 40px;

		.products-box__img {
			width: 70px;
			height: 80px;
			margin-top: 0;
			margin-bottom: 30px;
		}
	}
}

.s-products {
	&_all {
		background-color: transparent;
	}
}

.colapse {
	&-all {
		text-transform: uppercase;
		font-weight: 500;
		font-size: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 20px;

		> div {
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		.colapse-icon {
			margin-left: 10px;
			margin-right: 0;
		}
	}

	&-container {
		display: none;
		padding: 10px 0;
	}

	&-box {
		padding: 30px 30px 30px 0;
		overflow: hidden;
		display: flex;
		transition: box-shadow .3s ease;

		@media #{$tablet} {
			flex-direction: column;
		}

		div:first-child {
			@media #{$tablet} {
				text-align: center;
			}
		}

		&-inner {
			padding: 13px 0;
			border-bottom: 1px solid #cdcdcd;
		}
	}

	&-link {
		color: $title-color;
		font-size: 3.6rem;
		font-weight: 300;
		cursor: pointer;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #cdcdcd;
		user-select: none;
	}

	&-icon {
		border: 1px solid #e6e6e6;
		background-color: $white;
		border-radius: 50%;
		padding: 5px;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 18px;
		color: $title-color;
		font-size: 1.4rem;
		font-weight: 100;
		margin-right: 10px;
	}

	&-title {
		font-size: 4.8rem;
		color: $title-color;
		font-weight: 300;
		line-height: 0.8;
		padding-bottom: 40px;
	}

	&-item {
		overflow: hidden;
	}
}

.products-box__footer {
	a {
		text-transform: uppercase;
		font-size: 1.4rem;
		padding: 8px 20px;
		font-weight: 500;
		letter-spacing: 1.5px;

		&:first-child {
			border-right: 1px solid #cdcdcd;
		}
	}
}

.all-products-sidebar {
	padding-top: 44px;

	&__title {
		border-bottom: 1px solid #cdcdcd;
		padding-bottom: 13px;
	}

	&__filters {
		h5 {
			padding: 20px 0 5px;
		}
	}

	&__accordion {
		border-bottom: 1px solid #cdcdcd;
	}

	&__header {
		color: $title-color;
		text-transform: uppercase;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #cdcdcd;
		padding: 10px 0;
		cursor: pointer;
	}

	&__content {
		display: none;
		padding-bottom: 10px;
	}
}

.filters {
	&__item {
		font-size: 14px;
		float: left;
		background-color: #f5f5f5;
		border: 1px solid #ebebeb;
		padding: 2px 10px;
		color: $title-color;
		display: flex;
		align-items: center;
		margin: 0 10px 10px 0;

		i.fa {
			margin-left: 10px;
			cursor: pointer;
		}
	}
}

.clear-filters {
	color: $blue;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1.4rem;
	letter-spacing: 1.5px;
	cursor: pointer;
	padding: 30px 0 20px;
}

.icon {
	display: inline-block;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}

.icon-plus {
	&::before {
		background: url(../images/icon-plus.svg);
		width: 13px;
		height: 13px;
	}
}

#accordion .panel-title .collapse-link[aria-expanded=true] i:before {
	background: url(../images/icon-minus.svg);
	width: 12px;
	height: 2px;
}

#accordion .panel-title .collapse-link[aria-expanded=false] i:before {
	background: url(../images/icon-plus.svg);
	width: 13px;
	height: 13px;
}

.icon-minus {
	&::before {
		background: url(../images/icon-minus.svg);
		width: 12px;
		height: 2px;
	}
}

.icon-arrow {
	&::before {
		background: url(../images/icon-arrow.svg);
		width: 13px;
		height: 8px;
	}

	&.icon-arrow-up {
		transform: rotate(180deg);
	}
}