@import "../base/vars";
@import "../vendors/media";

html, body {
	height: 100%;
}

body {
	font-family: $default-font;
	font-size: 1.6rem;
	min-width: 320px;
	position: relative;
	line-height: 1.75;
	color: $default-color;
	overflow-x: hidden;
	opacity: 1;
	font-weight: 400;
}

button {
	outline: none;
}

h1, .h1 {
	color: $title-color;
	font-size: 4.8rem;
	font-weight: 300;
	font-family: $firasans;
	margin-top: 0;
	margin-bottom: 34px;
	line-height: 60px;

	@media #{$laptop} {
		font-size: 3.8rem;
		line-height: normal;
	}

	@media #{$tablet} {
		font-size: 3.2rem;
	}
}

h2, .h2 {
	color: $title-color;
	font-size: 3.6rem;
	font-weight: 300;
	padding: 3.3rem 0 3.2rem;
	margin: 0;
	font-family: $firasans;

	&.title-light {
		line-height: 46px;
	}

	@media #{$tablet} {
		font-size: 2.6rem;
	}
}

h3, .h3 {
	line-height: 30px;
	color: $title-color;
	font-size: 2.6rem;
	font-weight: 400;
	padding: 20px 0;
	margin: 0;
	font-family: $firasans;

	&.media-heading {
		padding-top: 0;
	}
}

h4, .h4 {
	color: $title-color;
	font-size: 2.2rem;
	font-weight: 400;
	text-transform: none;
	margin: 0;
	padding: 20px 0;
	font-family: $firasans;
}

h5, .h5 {
	color: $title-color;
	font-size: 1.8rem;
	font-weight: 500;
	text-transform: none;
	margin: 0;
	padding: 20px 0;
	font-family: $firasans;
}

h6, .h6 {
	color: $title-color;
	font-size: 1.6rem;
	font-weight: 500;
	text-transform: none;
	margin: 0;
	padding: 0;
	font-family: $firasans;
}

p {
	margin: 0 0 20px;
}

.font-size-18 {
	font-size: 1.8rem;
}

.title-medium {
	font-size: 2.6rem;

	+ p {
		padding-top: 1rem;
	}
}

b, strong {
	font-weight: 600;
}

section {
	padding: 80px 0 95px;

	@media #{$laptop} {
		padding: 60px 0 75px;
	}

	@media #{$tablet} {
		padding: 40px 0 55px;
	}
}

main {
	padding: 4.2% 0 5%;

	.page-content {
		padding: 3% 0;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}

.padding0 {
	padding: 0;
}

.margin0 {
	margin: 0;
}

.bg-section {
	background-color: $light-grey;
}

.container-fluid {
	max-width: 1440px;
	width: 100%;
}

.page-title {
	font-size: 6.2rem;
	font-weight: 500;
	line-height: 1.5;
	color: $white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: $firasans;
}

.gray-bg {
	background-color: #e9e9e9;
}

.blue-bg {
	background-color: #1782da;
}

.color-blue {
	color: $blue;
}

.vertical-align {
	display: flex;
	align-items: center;

	h3 {
		padding: 0;
	}
}

.white-color {
	color: $white;

	&:hover, &:focus {
		color: $white;
		text-decoration: none;
	}
}

.white-color-opacity {
	color: rgba($white, .7);
}

/* ====== Page Content ====== */
.page-content {
	clear: both;

	ul.disc {
		list-style-type: disc;
		padding: 1rem 0 1rem 1.8rem;
		margin: 0;

		ul {
			list-style-type: circle;
		}
	}

	ol {
		list-style-type: decimal;
		padding-left: 1.5rem;
	}
}

.padding-top {
	padding-top: 40px;
}

section.empty {
	padding: 10px 0;
}

.btn-green.btn-reverse:hover,
.btn-green.btn-reverse:active,
.btn-green.btn-reverse:focus {
	color: #fff;
	background-color: #29A653;
	border: 1px solid #29A653;
}

.media-left {
	padding-right: 30px;
}

.gray {
	background-color: $light-grey;
}

.grey {
	background-color: #3b3e43;
}

.yellow {
	background-color: #FFE141;
}

section.thin {
	padding: 0;
}

hr {
	border-color: #ddd;
}

.page-content:not(.section-indent) {
	padding-top: 0;
}

div.support-action {
	width: 92%;
	padding-bottom: 40px;
}

.padding-title {
	h3 {
		padding-top: 20px;
	}
}

/*===============================
	Content classes
===============================*/
div.indent-box {
	margin: 40px 0;
	padding: 0px 30px;
}

div.center {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

img.right {
	float: right;
	padding-left: 10px;
}

img.left {
	float: left;
	padding-right: 10px;
}

div.right, p.right {
	float: right;
}

.vertical {
	display: flex;
	align-items: center;
	width: 100%;
}

.vertical-center {
	min-height: 100%;
	display: flex;
	align-items: center;
}

/* ====== end Page Content ====== */

.bold-underline {
	&:hover {
		border-bottom: 2px solid $blue;
		text-decoration: none;
	}
}

.inline-block {
	display: inline-block;
}

.dark-link {
	color: $title-color;

	&:hover {
		color: $title-color;
		text-decoration: underline;
	}
}

.shadow {
	box-shadow: 0 9px 60px rgba(47, 47, 47, 0.15);
}

ul {
	padding: 5px 0 0 30px;

	li {
		padding-bottom: 5px;
	}
}

.nav-tabs > li {
	padding-bottom: 0;
	margin-right: 10px;
}

.caps {
	text-transform: uppercase;
}

.light {
	font-weight: 300;
}

.normal {
	font-weight: 400;
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.bold-underline-white {
	&:hover {
		border-bottom: 2px solid $white;
		text-decoration: none;
	}
}

div.screenshot-div {
	position: relative;
	text-align: left;

	a {
		background: url('/images/buttons/zoom_button.png') no-repeat 50% 50%;
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;

		&:hover {
			background: url('/images/buttons/zoom_button_active.png') no-repeat 50% 50%;
		}
	}
}

div.screenshot-div.right, div.demo-div.right {
	margin-left: 10px;
}

div.screenshot-div.left, div.demo-div.left {
	margin-right: 10px;
}

.page-content h1 a, .page-content h2 a, .page-content h3 a, .page-content h4 a, .page-content h5 a {
	color: $title-color;

	&:hover {
		color: $blue;
		text-decoration: none;
		border-bottom: 2px solid $blue;
	}
}

div.half-cell a:hover {
	text-decoration: none;
}




 




.modal-title {
	padding: 0;
}

.modal textarea {
	resize: none;
}



section.has-header {
	padding-top: 2rem;
}

section.wide-bg {
	padding-bottom: 3rem;
}

section.wide-bg .container-bg {
	position: absolute;
	left: -250px;
	width: 500px;
	height: 500px;
	overflow: hidden;
	z-index: -10;
}



hr.dashed {
	border-style: dashed;
}

img.right {
	float: right;
	padding: 0 0 0.5rem 1.5rem;
}

img.left {
	float: left;
	padding: 0 1.5rem 0.5rem 0;
}

a, .btn-link {
	color: #0071ce;
	outline: none !important;
}

a:hover {
	color: #0071ce;
}

.btn-link {
	text-transform: none;
	padding: 0;
	margin: 0;
	vertical-align: baseline;
}

.btn-link:hover .btn-link:active,
.btn-link:focus {
	color: #23527c;
	text-decoration: underline;
}

nav.vertical > a {
	display: block;
}

.container-fluid.narrow {
	max-width: 900px;
}

section.wide-bg .container-fluid {
	position: relative;
	padding-left: 250px;
}

.center-underline {
	width: 50%;
	height: 1px;
	border-top: 1px solid #777;
}

.row-equal-height {
	display: flex;
}

header span {
	vertical-align: middle;
}

.vertical-separator {
	border-right: 1px solid #ddd;
}

.vertical-separator.black {
	border-color: rgba(0, 0, 0, 0.1);
}

.vertical-separator-left {
	border-left: 1px solid #ddd;
}

/* ====== Icons ====== */

.icon-24 {
	display: inline-block;
	position: relative;
}

.icon-24.online {
	background-position: 0 0;
	float: right;
}

.icon-24.offline {
	background-position: 0 -24px;
	float: right;
}

.icon-24.cart {
	width: 24px;
	height: 18px;

	path {
		transition: all .3s ease;
		fill: #a4a3a5;
	}

	&:hover, &:focus {
		path {
			transition: all .3s ease;
			fill: #fff;
		}
	}
}

.icon-24.users {
	background-position: -48px 0;
	float: right;
}

.icon-24.search {
	width: 18px;
	height: 18px;

	path {
		transition: all .3s ease;
		fill: #a4a3a5;
	}

	&:hover, &:focus {
		path {
			transition: all .3s ease;
			fill: #fff;
		}
	}
}

.icon-24.english.active, a:hover .icon-24.english {
	background-position: -96px 0;
}

.icon-24.english {
	background-position: -96px -24px;
	margin-right: 0.5rem;
}

.icon-24.german.active, a:hover .icon-24.german {
	background-position: -120px 0;
}

.icon-24.german {
	background-position: -120px -24px;
	margin-right: 0.5rem;
}

.icon-24.russian.active, a:hover .icon-24.russian {
	background-position: -144px 0;
}

.icon-24.russian {
	background-position: -144px -24px;
	margin-right: 0.5rem;
}

.icon-24.twitter {
	background-position: 0 -48px;
}

a:hover .icon-24.twitter {
	background-position: 0 -72px;
}

.icon-24.facebook {
	background-position: -24px -48px;
}

a:hover .icon-24.facebook {
	background-position: -24px -72px;
}

.icon-24.linkedin {
	background-position: -48px -48px;
}

a:hover .icon-24.linkedin {
	background-position: -48px -72px;
}

.icon-24.youtube {
	background-position: -72px -48px;
}

a:hover .icon-24.youtube {
	background-position: -72px -72px;
}

.icon-24.googleplus {
	background-position: -96px -48px;
}

a:hover .icon-24.googleplus {
	background-position: -96px -72px;
}

/* ====== end Icons ====== */

/* ====== Common Sections ====== */

#user-area {
	background-color: #262626;
	height: 2.22rem;
	width: 100%;
	color: #fff;
	padding-top: 0.35rem;
}

#user-area a {
	color: #fff;
	cursor: pointer;
}

#user-area a:hover,
#user-area a:active,
#user-area a:focus {
	text-decoration: none;
}

#user-area #switch-language a {
	background: none;
	border: none;
	outline: none;
	padding: 0;
}

#control-area.banner-page {
	position: absolute;
	top: 2.22rem;
}

/* ====== end Common Sections ====== */


/* ====== Override third-party ====== */

.chosen-container-single .chosen-default {
	color: #999 !important;
}

/* ====== end Override third-party ====== */
