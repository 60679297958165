@import "../base/vars";
@import "../vendors/media";

.s-footer {
	background-color: #222227;
	padding: 60px 0 30px;

	@media #{$tablet} {
		padding: 40px 0 20px;
	}

	@media #{$mobile} {
		padding: 30px 0 15px;
	}
}

.footer {
	display: flex;
	justify-content: space-between;

	@media #{$laptop} {
		flex-wrap: wrap;
	}

	@media #{$tablet} {
		justify-content: flex-start;
	}

	&-logo {
		flex-basis: 300px;
		position: relative;

		@media #{$laptop} {
			width: 100%;
			text-align: center;
			padding-bottom: 40px;
			display: none;
		}
	}

	&-desc {
		font-size: 14px;
		line-height: 26px;
		color: rgba(255,255,255,0.4);
		padding-top: 18px;
	}

	.social {
		position: absolute;
		bottom: 0;
	}

	.social {
		.footer-list {
			display: flex;
		}

		&-item {
			display: inline-block;
			padding-right: 20px;
		}

		&-link {
			display: block;
			background-color: rgba($white, .08);
			width: 30px;
			height: 30px;
			border-radius: 50%;
			font-size: 1.5rem,;
			text-align: center;
			line-height: 30px;
			transition: background-color .3s;

			@media #{$laptop} {
				width: 40px;
				height: 40px;
				line-height: 40px;
				font-size: 2rem;
			}

			i.fa {
				color: $white;
				opacity: .8;
				transition: opacity .3s;
			}

			&:hover {
				i.fa {
					opacity: 1;
				}
			}

			&--facebook {
				&:hover {
					background-color: #415997;
				}
			}

			&--twitter {
				&:hover {
					background-color: #48aae0;
				}
			}

			&--google {
				&:hover {
					background-color: #d34c3e;
				}
			}

			&--in {
				&:hover {
					background-color: #2a77b4;
				}
			}

			&--youtube {
				&:hover {
					background-color: #e2232e;
				}
			}
		}
	}

	&-item {
		margin: 0 20px;

		@media #{$tablet} {
			margin: 0 2%;
		}
	}

	&-company {
		@media #{$desktop} {
			flex-basis: 22% !important;
		}

		@media #{$tablet} {
			flex-basis: 40% !important;
		}

		@media #{$mobile} {
			padding-bottom: 30px;
		}

		.footer-list-wrap {
			@media #{$desktop} {
				flex-direction: column;
			}

			@media #{$desktop} {
				flex-direction: row;
			}

			ul {
				@media #{$desktop} {
					width: 50%;
				}
			}
		}
	}

	&-products {
		ul {
			@media #{$desktop} {
				width: 50%;
			}
		}
	}

	> div {
		&:nth-child(2) {
			flex-basis: 399px;

			@media #{$laptop} {
				flex-basis: 40%;
			}

			@media #{$tablet} {
				flex-basis: 100%;
			}
		}

		&:nth-child(3) {
			flex-basis: 212px;

			@media #{$laptop} {
				flex-basis: 20%;
			}

			@media #{$tablet} {
				flex-basis: 44%;
			}
		}

		&:nth-child(4), &:nth-child(5) {
			flex-basis: 212px;

			@media #{$laptop} {
				flex-basis: 28%;
			}

			@media #{$tablet} {
				flex-basis: 230px;
			}
		}

		&:nth-child(2), &:nth-child(3) {
			@media #{$laptop} {
				margin-bottom: 30px;
			}
		}

		&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
			@media #{$mobile} {
				flex-basis: auto !important;
				width: 100%;
			}
		}
	}

	h3 {
		padding-top: 0;
		text-transform: uppercase;
		color: $white;
		margin: 0;
		font-size: 1.4rem;
		padding-bottom: 0;
		margin-bottom: 14px;
		text-align: left;
	}

	&-list {
		padding: 0;
		margin: 0;
		list-style-type: none;

		&-item {
			padding-bottom: 6px;
		}

		&-link {
			color: #a9a9ab;
			font-size: 1.4rem;
			text-decoration: none;
			transition: all .3s ease;

			&:hover {
				color: #008cff;
				text-decoration: none;
			}
		}
	}

	&-bottom {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-top: 45px;

		@media #{$laptop} {
			flex-direction: column;
			align-items: flex-start;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&-copy {
		font-size: 1.3rem;
		opacity: .2;
		color: $white;

		@media #{$laptop} {
			order: 2;
			padding-top: 20px;
		}

		@media #{$mobile} {
			width: 100%;
			text-align: center;
		}
	}

	&-partners {
		flex-basis: 770px;
		display: flex;
		justify-content: space-between;

		@media #{$laptop} {
			flex-basis: auto;
		}

		ul {
			display: flex;
			width: 100%;
			justify-content: space-between;
			margin: 0;
			padding: 0;
			list-style: none;

			@media #{$mobile} {
				flex-wrap: wrap;
			}

			img {
				max-width: 100%;
			}

			li {
				padding: 0;

				@media #{$laptop} {
					padding-right: 20px;
				}

				@media #{$mobile} {
					width: 50%;
					padding: 5px;
					text-align: center;
				}
			}
		}
	}

	&-list-wrap {
		display: flex;
		justify-content: space-between;
	}
}
