﻿@import "../base/vars";
@import "../vendors/media";

header {
	padding-top: 72px;

	@media #{$laptop} {
		padding-top: 0;
	}

	ul li {
		padding-bottom: 0;
	}
}

header.s-header {
	min-height: 900px;
	height: 100vh;
	position: relative;
	background: #1b1a1d url("../images/header-bg.jpg") no-repeat 70% 20%;
	padding-top: 0;

	@media #{$laptop} {
		height: auto;
		min-height: 100%;
	}

	@media #{$tablet} {
		background-position: 70% 0;
	}

	.menu-container {
		background: none;
	}

	.menu-bg {
		background-color: rgba(37, 41, 45, 0.9);
	}
}

#control-area {
	position: fixed;
	top: 0;
	transform: translateZ(0);
	width: 100%;
	z-index: 100;

	@media #{$laptop} {
		position: relative;
	}
}

.header-composition {
	display: none;
}

.logo-company {
	padding: 18px 0 22px 15px;
	float: left;
	position: relative;
	z-index: 102;

	@media #{$laptop} {
		padding: 12px 15px;
	}

	@media #{$tablet} {
		margin-right: 0;
		padding-right: 0;
	}
}

.menu-container-item {
	@media #{$laptop} {
		padding-right: 0;
	}
}

.menu-list {
	list-style: none;
	margin: 0;
	padding: 0;

	&-wrap {
		position: relative;
	}

	&-item {
		float: left;
		background: rgba(255,255,255,0);
		box-shadow: none;
		position: static;
		transition: all .3s ease;

		&:focus {
			background: transparent;
		}

		a.menu-list-link {
			font-family: $firasans;
			color: rgba(255,255,255,.85);
			font-size: 1.4rem;
			transition: color .25s ease;
			padding: 27px 20px 25px;
			line-height: 20px;
			position: relative;
			display: inline-block;
			text-decoration: none;
			cursor: pointer;

			&:hover {
				text-decoration: none;
				color: $white;
			}

			@media #{$desktop} {
				padding: 27px 15px;
			}

			&.cart {
				padding: 27px 20px 21px;
			}
		}
	}

	&-lang {
		position: relative;

		a:first-letter {
			text-transform: uppercase;
		}

		@media #{$laptop} {
			display: none;
		}
	}

	&-login {
		@media #{$laptop} {
			display: none;
		}

		.dropdown-arrow {
			left: auto;
			right: 30px;
			border-bottom-color: #f5f7f9;
		}
	}

	&.navbar-right {
		float: right;
		position: relative;
		z-index: 102;

		@media #{$laptop} {
			position: static;
		}
	}

	.mobile-menu {
		&:hover {
			@media #{$laptop} {
				background: transparent;
			}
		}
	}

	.dropdown-lang {
		min-width: 59px;
		padding-top: 0;
		padding-bottom: 0;
		border-radius: 0;
		overflow: hidden;

		a {
			font-size: 1.4rem;
			padding: 15px 20px;
			text-align: center;
			font-weight: 500;
			line-height: 1.42857143;
			color: #333;
			display: block;
			transition: background-color .3s ease;
			text-decoration: none;

			&:hover {
				background-color: #e7e7e7;
				text-decoration: none;
			}
		}
	}

	.dropdown-login {
		min-width: 240px;
		padding-top: 0;
		padding-bottom: 0;
		border-radius: 0;
		overflow: hidden;

		a {
			font-size: 16px;
			line-height: 30px;
			color: $title-color;
			font-family: $firasans;
			transition: color .3s ease;

			&:hover {
				text-decoration: none;
				color: $blue;
			}
		}

		.list-licenses, .list-profile, .sign-out {
			display: flex;
			align-items: center;
			padding: 16px 0;

			a {
				line-height: 17px;
			}
		}

		.list-profile {
			padding-top: 0;
		}

		.sign-out {
			border-top: 1px solid #d9d9d9;
		}
	}

	.head-user-dropdown {
		background-color: #f5f7f9;
		display: flex;
		padding: 15px;

		.user {
			width: 36px;
			height: 36px;
		}

		.user-info {
			padding-left: 13px;
			padding-top: 3px;
		}

		.user-name {
			font-size: 1.6rem;
			line-height: 16px;
			color: rgb(23,23,23);
			font-family: $firasans;
		}

		.user-email {
			font-size: 12px;
			line-height: 24px;
			color: rgb(174,174,174);
			line-height: 16px;
			padding-top: 3px;
		}
	}
}

.icon-licenses {
	background: url(../images/licenses.svg) no-repeat;
	width: 18px;
	height: 18px;
	display: inline-block;
	margin: 0 18px 0 24px;
}

.icon-edit-profile {
	background: url(../images/profile.svg) no-repeat;
	width: 18px;
	height: 18px;
	display: inline-block;
	margin: 0 18px 0 24px;
}

.icon-sign-out {
	background: url(../images/sign-out.svg) no-repeat;
	width: 20px;
	height: 16px;
	display: inline-block;
	margin: 0 18px 0 24px;
}

.dropdown-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	bottom: 0;
	left: 50%;
	margin-left: -12px;
	border-width: 0 12px 12px;
	border-bottom-color: #fff;
	display: none;
}

/* Start Nav Bar */
#control-area {
	&.fixed {
		position: fixed;
		top: 0;
		transform: translateZ(0);
		width: 100%;
		z-index: 100;

		@media #{$laptop} {
			position: relative;
		}
	}
}

#control-area .cart {
	&-number {
		border-radius: 50%;
		width: 18px;
		height: 18px;
		color: $white;
		background-color: $blue;
		font-size: 1rem;
		text-align: center;
		line-height: 20px;
		float: right;
	}
}

.menu-dropdown {
	opacity: 0;
	position: absolute;
	width: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	min-width: 160px;
	margin: 2px 0 0;
	list-style: none;
	font-size: 1.4rem;
	text-align: left;
	background-color: $white;
	background-clip: padding-box;
	top: -9999px;
	padding: 29px 0 0;
	box-shadow: none;

	h3 {
		padding-top: 15px;
		padding-bottom: 10px;
	}

	h2 {
		font-weight: 100;
		padding-top: 8px;
		padding-bottom: 26px;
	}
	/*.nav-dropdown {
		ul.nav-dropdown-list {
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}*/
}

.menu-list_main {
	@media #{$laptop} {
		display: none;
	}
}

.menu-container {
	transition: background-color .5s ease;
	background-color: #25292d;
}

#login-bar {
	a.login-link {
		color: rgba(255,255,255,.85);
		font-size: 1.4rem;
		transition: color .25s ease;
		padding: 28px 21px 24px;
		display: inline-block;
		font-family: $firasans;

		&:hover {
			color: $white;
		}

		@media #{$laptop} {
			padding: 20px 15px;
			color: #666;
		}
	}

	.signout {
		a:first-child {
			padding-right: 0;
		}

		a:last-child {
			padding-left: 0;
		}
	}
}

.search-form {
	margin: 0;
	padding: 0;
	border: none;
	box-shadow: none;
}

.search-button {
	padding: 23px 20px;
	background-color: transparent;
	border: none;

	@media #{$laptop} {
		padding: 16px 20px 11px;
	}

	i.fa {
		color: #fff;
		font-size: 2rem;
		cursor: pointer;
	}
}

/*Search*/
.searchbox {
	position: relative;
	min-width: 50px;
	width: 50px;
	height: 50px;
	float: right;
	overflow: hidden;
	transition: width 0.3s;
	top: 11px;
}

.searchbox-input {
	top: 0;
	right: 0;
	border: 0;
	outline: 0;
	background: transparent;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0px 55px 0px 20px;
	font-family: $firasans;
	font-weight: 300;
	font-size: 1.6rem;
	color: $white;

	@media #{$mobile} {
		width: 0;
	}
}

.searchbox-input::-webkit-input-placeholder {
	color: $white;
}

.searchbox-input:-moz-placeholder {
	color: $white;
}

.searchbox-input::-moz-placeholder {
	color: $white;
}

.searchbox-input:-ms-input-placeholder {
	color: $white;
}

.searchbox-icon,
.searchbox-submit {
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	top: 0;
	font-size: 22px;
	right: 0;
	padding: 0;
	margin: 0;
	border: 0;
	outline: 0;
	line-height: 54px;
	text-align: center;
	cursor: pointer;
	color: #dcddd8;
	background: transparent;
}

.searchbox-icon {
	display: inline-block;
	font-size: 20px;
}

.searchbox-open {
	width: 100%;
	box-shadow: 0 0 1px #fff;

	.searchbox-submit {
		background-color: #222227;
	}

	.searchbox-input {
		background-color: #1e1e23;
		width: 80rem;

		@media #{$desktop} {
			width: 60rem;
		}

		@media #{$laptop} {
			width: 100%;
		}
	}

	.searchbox-icon {
		.search {
			path {
				fill: #fff;
			}
		}
	}
}

@-webkit-keyframes autofill {
	to {
		color: #fff;
		background: #1e1e23;
	}
}

.searchbox input:-webkit-autofill {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}

.byline {
	position: absolute;
	top: 150px;
	left: 30%;
	text-align: center;
	font-size: 18px;
}

.byline a {
	text-decoration: none;
	color: #d74b4b;
}
/*=====================================*/

.dropdown-menu-full {
	width: 100%;
	right: 100%;
}

.nav-dropdown {
	ul {
		list-style-type: none;
		padding: 10px 0 0 0;
		margin: 0;

		li {
			padding-bottom: 7px;
		}

		a {
			color: #171717;
			font-family: $firasans;
			font-weight: 400;
			font-size: 1.6rem;
			transition: color .3s ease;

			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}
	}
}

.dropdown-list-group {
	padding: 0;
	margin: 0;

	li {
		list-style-type: none;
		padding-bottom: 18px;
		transition: all .3s ease;
		cursor: pointer;
		padding: 0 20px 5px;
		margin-bottom: 16px;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			background-color: $blue;

			h3 {
				color: $white;
			}

			p {
				color: rgba($white, .5);
			}
		}

		a {
			text-decoration: none;
			color: $default-color;

			&:hover, &:focus {
				text-decoration: none;
			}
		}
	}
}

.nav-tools {
	ul {
		padding-bottom: 18px;
	}
}

.dropdown-item {
	padding-bottom: 30px;
	display: flex;

	a {
		color: $blue;

		&:hover {
			text-decoration: underline;
		}
	}

	&-before {
		padding-right: 0;

		&::before {
			position: absolute;
			content: '';
			background-color: #d9d9d9;
			height: 100%;
			width: 1px;
			bottom: 0;
		}

		.dropdown-list-group {
			padding-left: 32px;
		}
	}

	&-after {
		padding-left: 0;

		&::after {
			position: absolute;
			content: '';
			background-color: #d9d9d9;
			height: 100%;
			width: 1px;
			bottom: 0;
			right: -15px;
		}
	}

	&-tools {
		padding-left: 50px;
	}

	&__title {
		font-size: 1.4rem;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 1.5px;
		position: relative;
		display: flex;
		align-items: center;
		min-height: 55px;

		span {
			position: relative;
			padding-right: 15px;
			padding-left: 10px;
		}
	}

	&__database {
		h3 {
			color: #9f62ff;
		}
	}

	&__connectivity {
		h3 {
			color: #67cc22;
		}
	}

	&__productivity {
		h3 {
			color: #2fc4c2;
		}
	}

	&__services {
		h3 {
			color: #3c8edb;
		}
	}

	&__tech {
		h3 {
			color: #ffba00;
		}
	}

	&__sales {
		h3 {
			color: #2fc4c2;
		}
	}

	&__about {
		h3 {
			color: #9f62ff;
		}
	}

	&__community {
		h3 {
			color: #2fc4c2;
		}
	}

	&__resources {
		h3 {
			color: #ffba00;
		}

		.dropdown-item__desc {
			padding-right: 0;
		}
	}

	&__social {
		h3 {
			color: #3c8edb;
		}
	}

	&__desc {
		font-size: 1.3rem;
		line-height: 20px;
		padding-right: 40px;
		margin-bottom: 10px;
	}
}

.dropdown-footer {
	border-top: 1px solid #d9d9d9;
	padding-top: 30px;
	padding-bottom: 30px;

	a {
		text-decoration: none;
		font-weight: 500;
		transition: color .25s ease;
		font-family: $firasans;
		font-size: 1.4rem;

		&:first-child {
			margin-right: 20px;
		}
	}
}

.dropdown-support-wrap {
	p {
		margin-bottom: 0;
		max-width: 310px;
		color: #6a6a6a;
	}

	ul {
		padding-top: 56px;
		padding-left: 20px;
	}
}
/* End Nav Bar */
/*Start Nav Mobiloe*/
#nav-mobile {
	float: right;
	display: none;

	@media #{$laptop} {
		display: block;
	}
}

#nav-mobile a {
	text-decoration: none;
	color: #666;
}

#nav-mobile .toggle-btn {
	display: block;
	position: relative;
	background: transparent;
	margin: 0;
	padding: 0;
	width: 70px;
	height: 64px;
	text-indent: -9999px;
	z-index: 11;
	border: 0;
	cursor: pointer;
	transition: background 0.2s;
}

#nav-mobile .is-active {
	background: $white;
}

#nav-mobile .toggle-btn:focus {
	outline: none;
}

#nav-mobile span.toggle {
	display: block;
	position: absolute;
	top: 35px;
	left: 18px;
	right: 18px;
	height: 2px;
	width: 18px;
}

#nav-mobile span.toggle:before,
#nav-mobile span.toggle:after {
	position: absolute;
	display: block;
	left: 0;
	width: 18px;
	height: 2px;
	content: "";
}

#nav-mobile span.toggle:before {
	top: -7px;
}

#nav-mobile span.toggle:after {
	bottom: -7px;
}

#toggle-nav {
	.toggle-btn {
		span {
			&.toggle {
				background-color: $white;
				transition: background 0s 0.3s;

				&::before, &::after {
					background-color: $white;
					transition-duration: 0.3s, 0.3s;
					transition-delay: 0.3s, 0s;
				}

				&::before {
					transition-property: top, transform;
				}

				&::after {
					transition-property: bottom, transform;
				}
			}
		}

		&.is-active {
			&::before {
				top: 0;
			}

			span {
				&.toggle {
					background: none;

					&::before, &::after {
						transition-delay: 0s, 0.3s;
						background: $title-color;
					}

					&::before {
						top: 0;
						transform: rotate(45deg);
					}

					&::after {
						bottom: 0;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}

.nav-slide {
	background-color: transparent;
	position: absolute;
	display: none;
	bottom: 0;
	right: 0;
	top: 64px;
	width: 100%;
	z-index: 10;
	transition: all 0.25s ease-in-out 0s;

	&.is-active {
		display: block;
	}

	.slide-menu {
		display: inline-block;
		float: left;
		margin: 0;
		width: 100%;

		li {
			display: block;

			a {
				display: inline-block;
				width: 100%;
				text-transform: uppercase;
				cursor: pointer;
				padding: 15px 30px;
			}
		}
	}

	@media #{$tablet} {
		width: 100%;
		left: 0;
	}
}

.mobile-dropdown-menu, .mobile-dropdown-menu ul, .mobile-dropdown-menu ul li, .mobile-dropdown-menu ul li a {
	display: block;
	list-style: none outside none;
	margin: 0;
	padding: 0;
	position: relative;
}

.mobile-dropdown-menu {
	background: $white;
	box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);

	> ul {
		> li {
			> a {
				background: $white;
				cursor: pointer;
				font-family: $default-font;
				text-transform: uppercase;
				font-size: 1.6rem;
				padding: 20px 15px;
				text-decoration: none;
				z-index: 2;

				&:hover {
					color: $title-color;
				}
			}

			&.active, &.open {
				> a {
					color: $title-color;
				}
			}
		}
	}

	ul {
		ul {
			display: none;

			li {
				a {
					background: #f2f2f2;
					color: $title-color;
					font-family: $default-font;
					font-size: 1.6rem;
					cursor: pointer;
					padding: 15px 15px;
					text-decoration: none;
					z-index: 1;
				}

				.all {
					span {
						display: block;
						color: $white;
						background: $title-color;
						padding: 17px 0;
						text-align: center;
					}
				}

				.free {
					span {
						display: block;
						color: $white;
						background: #4bb900;
						padding: 17px 0;
						text-align: center;
					}
				}

				&:first-child {
					> a {
						box-shadow: none;
					}
				}

				&.has-sub {
					> a {
						&::after {
							display: block;
							height: 5px;
							position: absolute;
							right: 20px;
							top: 15px;
							z-index: 10;
							content: "\f107";
							font-family: FontAwesome;
							color: $title-color;
						}
					}

					ul {
						li {
							> a {
								&::after {
									content: "";
								}
							}
						}
					}
				}

				&.open {
					a {
						&::after {
							content: "\f106";
						}

						ul {
							li {
								a {
									&::after {
										content: "";
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.sub-sub-menu {
		a {
			background: #e8e8e8;
		}
	}
}
/*End Nav Mobiloe*/
/* Start Header Text */
.header-composition {
	width: 100%;
	font-size: 6.2rem;
	letter-spacing: 1.5px;
	font-weight: 500;
	font-family: $firasans;
	color: $white;
	padding-top: 5rem;
	padding-bottom: 10rem;

	@media #{$desktop} {
		font-size: 5rem;
		/*padding-bottom: 100px;*/
	}

	@media #{$laptop} {
		padding-bottom: 40px;
		padding-top: 40px;
		font-size: 4rem;
	}

	@media #{$mobile} {
		font-size: 2.6rem;
	}

	> div {
		@media #{$tablet} {
			float: none !important;
		}
	}

	&-wrap {
		display: none;
	}

	.title-middle {
		padding-left: 20px;
		line-height: 100px;

		@media #{$desktop} {
			line-height: 80px;
		}

		@media #{$laptop} {
			line-height: 68px;
		}

		@media #{$tablet} {
			float: none !important;
			padding-left: 0;
			line-height: 40px;
			padding-bottom: 20px;
		}

		@media #{$mobile} {
			line-height: 26px;
			padding-bottom: 10px;
		}
	}

	.title-bottom {
		line-height: 60px;

		@media #{$tablet} {
			line-height: 40px;
		}
	}

	h1 {
		font-size: 6.2rem;
		letter-spacing: 1.5px;
		font-weight: 500;
		font-family: $firasans;
		margin: 0;

		strong {
			font-weight: 500;
		}

		@media #{$laptop} {
			font-size: 4.4rem;
		}

		@media #{$tablet} {
			font-size: 3rem;
		}

		@media #{$mobile} {
			font-size: 1.6rem;
		}
	}

	h1 {
		font-size: 5rem;

		@media #{$tablet} {
			font-size: 4rem;
		}

		@media #{$mobile} {
			font-size: 3rem;
		}
	}

	&-button {
		text-align: center;
		padding-top: 33px;

		a {
			border: 2px solid $blue;
			display: inline-block;
			padding: 10px 40px;
			color: $white;
			text-decoration: none;
			font-size: 1.8rem;
			text-transform: uppercase;
			transition: background-color 0.3s ease;
			font-family: $firasans;
			font-weight: 500;
			letter-spacing: 1.5px;

			&:hover {
				background-color: $blue;
			}

			.navbar-collapse.collapse.in {
				background-color: #04182f;
				position: relative;
				z-index: 1;
			}
		}
	}
}

.type-text {
	font-size: 6.2rem;
	font-weight: 300;
	color: #008aff;

	@media #{$desktop} {
		font-size: 5rem;
	}

	@media #{$laptop} {
		font-size: 4rem;
	}

	@media #{$tablet} {
		font-size: 3.6rem;
	}

	@media #{$mobile} {
		font-size: 2.6rem;
	}

	&-list {
		margin: 0;
		padding: 0;
		text-align: center;
		list-style: none;

		&-item {
			line-height: 40px;
			margin: 0;
			position: absolute;
			display: none;
			transform: translate(-50%, 0);
			left: 50%;
			width: 100%;
		}
	}
}

.typed-cursor {
	opacity: 1;
	animation: blink 0.7s infinite;
	font-weight: 300;
	color: #008aff;
	font-size: 6rem;
	position: relative;
	top: 0;

	@media #{$laptop} {
		font-size: 4rem;
	}

	@media #{$tablet} {
		top: 0;
	}

	@media #{$mobile} {
		font-size: 2.6rem;
		top: -2px;
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-ms-keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
/* End Animation header text */
/* End Header Text */

.header-additional {
	background: url("../images/header-additional.png") no-repeat;
	min-height: 290px;
	position: relative;
	background-position: top center;
}

.header-regular, .header-feedback {
	/*position: absolute;
	width: 100%;
	z-index: 1;*/
	background-color: #25292d !important;
}

.productss-box {
	position: absolute;
	bottom: 0;
	width: 100%;
}

/* Video background animate */
.fullscreen-bg {
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
	padding-top: 45%;
}

.fullscreen-bg__video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
/* ========================== */

.main-menu {
	position: absolute;
	width: 100%;
	left: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	z-index: 101;
}

.container-composition-products {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;

	@media #{$laptop} {
		position: relative;
		transform: none;
		left: auto;
		top: auto;
	}
}

/* Mouse scroll button */
.mouse-scroll-wrap {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%, 0);
	display: none;
	width: 24px;
	height: 40px;
}

.mouse-scroll {
	display: block;
	width: 26px;
	height: 40px;
	border: 2px solid $white;
	border-radius: 50px;
	position: relative;
	cursor: pointer;
	margin: 0 auto;
	transform: translate(0,0);
	overflow: hidden;
	font-size: 10000px;

	&::after {
		content: '';
		width: 4px;
		height: 8px;
		position: absolute;
		left: 50%;
		top: 7px;
		border-radius: 4px;
		background-color: $white;
		margin-left: -2px;
		opacity: 1;
		transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
		-webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
		animation: 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s normal none infinite running scrollAnimation;
		-webkit-animation: 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s normal none infinite scrollAnimation;
	}
}

@keyframes scrollAnimation {
	0%, 20% {
		transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px)
	}

	10% {
		opacity: 1;
		transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px)
	}

	100% {
		opacity: 0.01;
		transform: translateY(16px) scaleY(2.5) scaleX(0.5) translateZ(0px)
	}
}

@-webkit-keyframes scrollAnimation {
	0%, 20% {
		-webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px)
	}

	10% {
		opacity: 1;
		-webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px)
	}

	100% {
		opacity: 0.01;
		-webkit-transform: translateY(16px) scaleY(2.5) scaleX(0.5) translateZ(0px)
	}
}

.scroll-to-anchor {
	width: 28px;
	height: 28px;
	display: block;
	border: 1px solid $white;
	border-radius: 50%;
	color: $white;
	text-align: center;
	line-height: 27px;
	opacity: .25;
	animation: pulsearrow 2s linear alternate infinite;

	i {
		color: $white;
		font-size: 16px;
	}
}

@keyframes pulsearrow {
	to {
		opacity: 1;
	}
}
/*===========================================*/

.overlay-page {
	background: #000;
	opacity: 0;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: none;
}
